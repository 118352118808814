import React, { useEffect, useState } from 'react';
import './App.css';
import CookieConsent, {getCookieConsentValue, resetCookieConsentValue} from "react-cookie-consent";

const loadGTM = () => {
    (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-MDMZZGH9');
  };

function App() {
    // Initial consent state is set based on the current cookie consent value
    const [cookieConsent, setCookieConsent] = useState(getCookieConsentValue("redeggConsent") === "true");

    useEffect(() => {
        // Load GTM only if consent is given
        if (cookieConsent) {
            loadGTM();
        }
    }, [cookieConsent]);

    useEffect(() => {
        const handleCookieSettingsChange = () => {
            resetCookieConsentValue('redeggConsent');
            setCookieConsent(false); // Update the state to reflect the consent reset
        };

        // Attaching the event listener to the external button
        const button = document.getElementById('changeCookieSettings');
        if (button) {
            button.addEventListener('click', handleCookieSettingsChange);
        }

        // Cleanup function to remove the event listener
        return () => {
            if (button) {
                button.removeEventListener('click', handleCookieSettingsChange);
            }
        };
    }, []);

    const handleAccept = () => {
        setCookieConsent(true); // Update the state to reflect consent given
    };

    const handleDecline = () => {
        setCookieConsent(false); // Update the state to reflect consent declined
    };

  return (
    <>
    {cookieConsent === false && (
        <CookieConsent
        buttonText="Akzeptieren"
        declineButtonText="Ablehnen"
        enableDeclineButton
        ariaAcceptLabel="Redegg Cookies Akzeptieren"
        ariaDeclineLabel="Redegg Cookies Ablehnen"
        onAccept={handleAccept}
        onDecline={handleDecline}
         style={{background: "#000", padding: "10px", position: "fixed", bottom: "0", left: "0", right: "0", zIndex: "1000", display: "flex", justifyContent: "space-between", alignItems: "center"}}
       contentStyle={{flex: "0 0 90%", textAlign: "left", padding: "10px 300px 0 0", fontSize: "16px", lineHeight: "1.55", color: "#fff"}}
       buttonStyle={{flex: "0 0 5%", padding: "10px", fontSize: "16px", lineHeight: "1.55", color: "#fff", background: "#de1a1b", border: "none", cursor: "pointer"}}
       declineButtonStyle={{flex: "0 0 5%", padding: "10px", fontSize: "16px", lineHeight: "1.55", color: "#fff", background: "transparent", border: "none", cursor: "pointer", textDecoration: "underline"}}
        expires={30}
        cookieName="redeggConsent"
        containerClasses="cookie-consent-container"
        buttonClasses="cookie-accept-button"
           declineButtonClasses="cookie-decline-button"
           contentClasses="cookie-content"
        >
       Wir nutzen auf unseren Webseiten Cookies und Trackingtechnologien. Diese dienen der Optimierung unserer Website, der Weiterentwicklung von Services und Marketingzwecken. Der Einsatz bestimmter Cookies ist für die uneingeschränkte Nutzung unserer Website technisch erforderlich. Durch Klick auf „Akzeptieren“ stimmen Sie zu, dass auch Cookies zu Analyse-, Marketing- und Social Media-Zwecken gesetzt werden. Die Einwilligung können Sie jederzeit widerrufen. Weitere Informationen sowie die Widerspruchsmöglichkeit finden Sie in unserer <a style={{textDecoration:"underline", color:"#fff"}} href="https://redegg.de/datenschutz.html">Datenschutzinformation</a>.
       </CookieConsent>
        )}
    </>
  );
}

export default App;
